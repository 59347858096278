import { useEffect, useState } from 'react';
import { ExchangeRelay } from '../../../models/exchangeRelay';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../../hooks/redux-hooks';
import { selectAuth } from '../../../redux/slices/authSlice';

import { Case } from '../../../models/Case/case';
import { collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../../firebaseConfig';
import { fetchInquiriesByCaseUid } from '../../../services/inquiry-data.service';
import { carrierContacts } from '../../../types/carrierContacts';
import "./offerdetailreport.css";
import { Inquiry } from '../../../models/Case/inquiry';
import ExchangeHeader from '../../../components/ExchangeHeader/exchangeheader';

const OfferDetailReport = () => {
  const { uid } = useParams<{ uid: string }>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [caseData, setCaseData] = useState<Case | null>(null);
  const [inquiry, setInquiry] = useState<Inquiry | null>(null);
  const [exchangeRelays, setExchangeRelays] = useState<ExchangeRelay[]>([]);  

  const { userData } = useAppSelector(selectAuth);

  useEffect(() => {
    const fetchCaseData = async () => {
      if (!uid) return;
  
      try {
        // Fetch case data
        const caseDocRef = doc(db, "cases", uid);
        const caseDoc = await getDoc(caseDocRef);
  
        if (!caseDoc.exists()) {
          setError("No such document in cases!");
          return;
        }
  
        const caseData = caseDoc.data();
        setCaseData(caseData);
  
        // Fetch inquiries related to the case
        const inquiries = await fetchInquiriesByCaseUid(uid);
        if (inquiries?.length > 0) {
          setInquiry(inquiries[0]);
        }
  
        // Fetch exchangeRelays related to the case
        const relayQuery = query(collection(db, "exchangeRelay"), where("caseUid", "==", uid));
        const snapshot = await getDocs(relayQuery);
        const relays = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() } as ExchangeRelay));
        setExchangeRelays(relays);
      } catch (error) {
        setError("Error fetching case data");
        console.error("Error fetching case data:", error);
      }
    };

    fetchCaseData().finally(() => setLoading(false));
  }, [uid, userData]);

  const displayCarrier = (carrierCode: string | undefined) => {
    return userData?.carrierCode === carrierCode || !userData?.isCarrier;
  };

  if (error) return <p>Error: {error}</p>;
  
  return (
    <div className="offer-detail-report-container">
      <div className="offer-logo-section">
        <ExchangeHeader exchangePartner={userData?.exchangePartner || ''} />
      </div>
      <div className="case-details">
        {caseData && <span className="subheader">{caseData.lastName}, {caseData.firstName}</span>}
        {inquiry && (
          <>
            <span className="subheader">Face Amount: {inquiry.faceAmount}</span>
            <span className="subheader">Target Premium: {inquiry.targetPremium}</span>
          </>
        )}
      </div>

      {loading && <h1>Loading...</h1>}
      {error && 
        <div className="error-message">
          <p>Error: Unable to load the document. Please try again later or contact support@apeirongate.com</p>
        </div>
      }
      {!loading && !error &&
        <div>
          {
            exchangeRelays.map((relay, index) => {
              const carrier = carrierContacts.find((r) => r.code === relay.carrierCode);
              return(
                <div className="offer-details-container" key={index}>
                  <div className="offer-details-report-header">
                    <div>
                      <span className="label">Carrier: </span>{displayCarrier(relay.carrierCode) ? carrier?.name : '*****' }
                    </div>
                    { relay.carrierId && displayCarrier(relay.carrierCode) &&
                      <div>
                        <span className="label">ID#: </span>{relay.carrierId}
                      </div>
                    }
                    <div>
                      <span className="label">Tentative Offer: </span>{relay.tentativeOffer} {relay.tentativeOfferSubCategory} 
                    </div>
                  </div>
                  <div className="offer-details">
                    <span dangerouslySetInnerHTML={{ __html: relay.offerDetails ? relay.offerDetails.replace(/\n/g, "<br />") : "Offer details not provided.", }} />
                  </div>
                </div>
              );
            })
          }
        </div>      
      }
    </div>
  );
};

export default OfferDetailReport;

