import * as XLSX from "xlsx";
import { OpportunityItem } from "./opportunityreport";


export const exportORToExcel = (items: OpportunityItem[], fileName: string) => {
  // Add the  table
  const headers = ["Marketing Rep", "Case Manager", "Agent", "Applicant","Carrier", "Offer Date", "Target Premium", "Face Amount", "Tentative Offer", "Placed"];
  const data = items.map((item) => [
    item.marketingRep || "",
    item.caseManager || "",
    item.agent || "",
    item.applicant || "",
    item.carrier || "",
    item.offerDate || "",
    item.targetPremium || "",
    item.faceAmount || "",
    item.tentativeOffer || "",
    item.placed || "",
  ]);

  // Combine summary and detail tables
  const combinedData = [
    headers,
    ...data,
  ];

  // Create a worksheet
  const worksheet = XLSX.utils.aoa_to_sheet(combinedData);

  // Create a workbook
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Offer Data");

  // Export to Excel
  XLSX.writeFile(workbook, `${fileName}.xlsx`);
};
  
