import  { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import { Case, CaseStatus, FeedbackRequest } from '../../../models/Case/case';
import { useAppSelector } from '../../../hooks/redux-hooks';
import { selectAuth } from '../../../redux/slices/authSlice';
import { useFilteredData } from '../../../services/case-data.service';

import "./casemanager.css";
import { doc, updateDoc } from 'firebase/firestore';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { REQUEST_STATUS } from '../../../types/feedbackTypes';
import { InquiryStatus, SummaryStatus } from '../../../types/caseTypes';
import { db } from '../../../firebaseConfig';
import { CaseManagerSearchRequest } from '../../../types/caseSearchRequest';
import CaseSearchForm from '../../../components/CaseSearchForm/casesearchform';
import CaseFeedbackModal from '../../../components/FeedbackRequest/feedbackrequestmodal';
import CasePlacementEntry from '../../../components/CasePlacedEntry/caseplacemententry';


const CaseManager = () => {
  const navigate = useNavigate();
  const { userData } = useAppSelector(selectAuth);
  const [modalOpen, setModalOpen] = useState(false); 
  const [placedModalOpen, setPlacedModalOpen] = useState(false);  
  const [selectedStatus, setSelectedStatus] = useState('');  
  const [selectedCase, setSelectedCase] = useState<Case | null>(null);
  const [currentPageData, setCurrentPageData] = useState<Case[]>([]);
  
  const initialValues: CaseManagerSearchRequest = {
    caseStatus: "Active",
    firstName: "",
    lastName: "",
    showOrgCases: false,
    batchSize: 10,
    userEmail: userData?.email || ""
  };
  
  const [hasNextPage, setHasNextPage] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchFormValues, setSearchFormValues] = useState(initialValues);
  const [filters, setFilters] = useState<CaseManagerSearchRequest>(initialValues);
  const [cachedPages, setCachedPages] = useState<Case[][]>([]);
  const [lastVisible, setLastVisible] = useState<any>(null);

  const { data, isLoading, refetch } = useFilteredData(filters, currentPage, lastVisible, setLastVisible);

  const areFiltersDifferent = (a: CaseManagerSearchRequest, b: CaseManagerSearchRequest): boolean => {
    return Object.keys(a).some((key) => a[key as keyof CaseManagerSearchRequest] !== b[key as keyof CaseManagerSearchRequest]);
  };

  const handleSearch = () => {
    if (areFiltersDifferent(searchFormValues, filters)) {
      setCachedPages([])
      setCurrentPage(1);
      setLastVisible(null);
      setFilters(searchFormValues);
    }
  };

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = event.target;
    setSearchFormValues({
      ...searchFormValues,
      [name]: value,
    });
  };

  useEffect(() => {
    refetch().then((result: any) => {
      if (result?.data?.caseData) {
        setCachedPages([result.data.caseData]); // Initialize cached pages
        setCurrentPageData(result.data.caseData); // Set data for the first page
        setHasNextPage(result.data.hasNextPage); // Set pagination state
      }
    });
  }, [filters, refetch]);

  useEffect(() => {
    if (data) {
      if (data.caseData[0]?.uid === currentPageData[0]?.uid && data.caseData.length === currentPageData.length) {
        setCurrentPageData(data.caseData);
      }
    }
  }, [data, currentPageData]);

  const handlePageChange = async (newPage: number) => {
    //if next clicked
    if (newPage > currentPage) {
      //if page not already in cache
      if (newPage > cachedPages.length) {
        const result = await refetch();
        if (result?.data?.caseData) {
          setHasNextPage(result.data.hasNextPage)
          setCachedPages((prev) => [...prev, result.data.caseData]);
          setCurrentPageData(result.data.caseData);
        }
      } else {
        const updatedPageData = cachedPages[newPage - 1] || [];
        setHasNextPage(searchFormValues.batchSize === updatedPageData.length)
        setCurrentPageData(updatedPageData);
      }
    } else {
      //if previous clicked
      setHasNextPage(true)
      const updatedPageData = cachedPages[newPage - 1] || [];
      setCurrentPageData(updatedPageData);
    }
    setCurrentPage(newPage);
  };

  const handleOrgCasesChange = (checked: boolean) => {
    setSearchFormValues({
      ...searchFormValues,
      showOrgCases: checked,
    });
  }

  const handleViewCase = (uid: string) => navigate(`/case/${uid}`);
  const handleSendCase = (uid: string, appId: string) => navigate(`/sendqueue/${uid}/${appId}`);
  const handleViewOffer = (uid: string) => navigate(`/case/${uid}/offerDetail`);

  const getExchangeName = (): string => {
    if (userData?.exchangePartner === 'LS') {
      return 'Lion Street Exchange';
    }
  
    return 'AIN Exchange';
  };

  const handleReqestFeedback = (caseUid: string) => {
    const caseData = data?.caseData.find((c) => c.uid === caseUid);

    if (caseData) {
      setSelectedCase(caseData);
      setModalOpen(true);
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleClosePlacedModal = () => {
    setPlacedModalOpen(false);
  };

  const handleStatusChange = async (e: React.ChangeEvent<HTMLSelectElement>, caseUid: string) => {
    const newStatus = e.target.value as CaseStatus;
    setSelectedStatus(newStatus)
  
    try {
      if (newStatus === 'Placed' || newStatus === 'No Acceptable Offer') {
        const caseData =  currentPageData.find((c) => c.uid === caseUid);

        if (caseData) {
          setSelectedCase(caseData);
          setPlacedModalOpen(true);
        }
      } else {
        const docRef = doc(db, "cases", caseUid);
        await updateDoc(docRef, { caseStatus: newStatus, placedCarrier: '', placedNote: '' });

        // Update the local state to reflect the status change
        updateCaseStatusInPages(caseUid, newStatus);
      }
    } catch (error) {
      console.error("Failed to update status:", error);
    }
  };

  const updateCaseStatusInPages = (caseUid: string, newStatus: CaseStatus) => {
    setCachedPages((prev) =>
      prev.map((page) =>
        page.map((caseItem) =>
          caseItem.uid === caseUid ? { ...caseItem, caseStatus: newStatus } : caseItem
        )
      )
    );
  };
  
  
  const columns: any[] = [
    {  
      name: 'Status',
      cell: (row: { uid: string, caseStatus: string }) => (
        <select id="caseStatus" value={row.caseStatus} onChange={(e) => handleStatusChange(e, row.uid)} className="case-select" >
          <option value="Active">Active</option>
          <option value="Hold">Hold</option>
          <option value="Placed">Placed</option>
          <option value="No Acceptable Offer">No Acceptable Offer</option>
          <option value="Underwriting Review">Underwriting Review</option>
        </select>
      ),
    },
    {  
      name: 'First Name',  
      selector: (row: { firstName: any; }) => row.firstName,
      sortable: true,
    },
    {  
      name: 'Last Name',  
      selector: (row: { lastName: any; }) => row.lastName,
      sortable: true,
    },
    {  
      name: 'DOB',  
      selector: (row: { dob: any; }) => {
        if (row.dob) {
          const [year, month, day] = row.dob.split('-');
          return `${month}/${day}/${year}`;
        }
        return '';
      },
    },
    {  
      name: 'Summary Status',
      cell: (row: { summaryStatus: string; appId: string }) => (
        row.summaryStatus === SummaryStatus.COMPLETED ? (
          <a href={`/case/${row.appId}/summary`}>
            {row.summaryStatus}
          </a>
        ) : (
          row.summaryStatus
        )
      ),
    },
    {
      name: 'Informal Inquiry',
      cell: (row: { uid: string; inquiryStatus: string }) => (
        <div className="left-align">
          <button
            onClick={() => handleViewCase(row.uid)}
            className={`inquiry-button ${row.inquiryStatus !== InquiryStatus.COMPLETED ? 'incomplete-status' : ''}`}
          >
            {row.inquiryStatus !== InquiryStatus.COMPLETED ? 'Start' : 'Revisit'}
          </button>
        </div>
      ),
    },
    {
      name: getExchangeName(),
      cell: (row: { uid: string, appId: string, relayIds: string[] }) => (
        <div className="left-align">
          <button onClick={() => handleSendCase(row.uid, row.appId)} className="inquiry-button">
            Shop ({row.relayIds?.length || 0})
          </button>
        </div>
      ),
    },
    {
      name: 'Offers',
      cell: (row: { uid: string, offeredRelayIds: string[] }) => (
        <div className="left-align">
          { row.offeredRelayIds?.length > 0 &&
            <button onClick={() => handleViewOffer(row.uid)} className={`inquiry-button`}>
              View ({row.offeredRelayIds?.length || 0})
            </button>
          }
        </div>
      ),
    },
    {
      name: 'Feedback',
      cell: (row: { uid: string, feedbackRequest: FeedbackRequest }) => (
        <div className="left-align">
          <button onClick={() => handleReqestFeedback(row.uid)} 
            className={`feedback-button 
              ${row.feedbackRequest?.feedbackStatus === REQUEST_STATUS.RESPONDED ? 'feedback-responded' : ''}
              ${row.feedbackRequest?.feedbackStatus === REQUEST_STATUS.REQUESTED ? 'feedback-requested' : ''}
            `}  
          >
            {row.feedbackRequest === undefined ? 'Submit' : row.feedbackRequest.feedbackStatus }
          </button>
        </div>
      ),
    }
  ];

  return (
    <div className='casemanager-container'>      
      {isLoading && <h1>Loading...</h1>}
      {!isLoading && 
        <div className='data-table'>
          <div className="form-section">
            <h2>Trial Manager</h2>

            <CaseSearchForm
              values={searchFormValues}
              onChange={handleFilterChange}
              onSearch={handleSearch}
              onOrgCasesChange={handleOrgCasesChange}
            />
          </div>

          <DataTable
            columns={columns}
            data={currentPageData}
            highlightOnHover
            pagination
            paginationPerPage={searchFormValues.batchSize}
            paginationServer
            paginationComponent={() => (
              <div className="pagination">
                <button className="pagination-button"
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1 || isLoading}
                >
                  Previous
                </button>
                <button className="pagination-button"
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={!hasNextPage || isLoading}
                >
                  Next
                </button>
              </div>
            )}
          />
        </div>
      }
      
      <CaseFeedbackModal
        open={modalOpen}
        onClose={handleCloseModal}
        caseUid={selectedCase?.uid || ""}
        firstName={selectedCase?.firstName || ""}
        lastName={selectedCase?.lastName || ""}
        setModalOpen={setModalOpen}
      />

      <Modal open={placedModalOpen} onClose={handleClosePlacedModal}>
        <Box sx={{ 
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 600,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          maxHeight: '80vh',
          overflowY: 'auto',
        }}>          
          <CasePlacementEntry 
            placed={selectedStatus === 'Placed'}
            caseUid={selectedCase?.uid || ""}
            firstName={selectedCase?.firstName || ""}
            lastName={selectedCase?.lastName  || ""} 
            setModalOpen={setPlacedModalOpen}
            onCaseStatusUpdate={(status: CaseStatus) => {
              updateCaseStatusInPages(selectedCase?.uid || "", status)
            }}
          /> 
        </Box>
      </Modal> 
    </div>
  );
};

export default CaseManager;
