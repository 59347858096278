import { db } from '../firebaseConfig';
import {
  doc,
  updateDoc,
  collection,
  query,
  where,
  limit,
  onSnapshot,
  getDocs,
  orderBy,
  startAfter,
  or,
  and,
  QueryFilterConstraint,
} from 'firebase/firestore';
import { useQuery, useQueryClient } from 'react-query';
import { Case } from '../models/Case/case';
import { REQUEST_STATUS } from '../types/feedbackTypes';
import { CaseManagerSearchRequest } from '../types/caseSearchRequest';
import { useRef } from 'react';

const collectionRef = collection(db, 'cases');


// Soft delete a case
export const deleteCase = async (caseId: string): Promise<void> => {
  try {
    const caseRef = doc(collectionRef, caseId);
    await updateDoc(caseRef, { isDeleted: true });
  } catch (error) {
    console.error("Error deleting document: ", error);
    throw error;
  }
};

export const getCaseByAppId = async (appId: string): Promise<Case | null> => {
  try {
    const q = query(collectionRef, where("appId", "==", appId));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const caseDoc = querySnapshot.docs[0];
      return caseDoc.data();
    } else {
      console.log("No case found with the given appId");
      return null;
    }
  } catch (error) {
    console.error("Error fetching document: ", error);
    throw error;
  }
};


export const carrierCaseManagerFetchCases = (
  caseUids: string[],
  callback: (cases: Case[]) => void
): (() => void) => {
  const casesRef = collection(db, 'cases');
  const casesQuery = query(casesRef, where('__name__', 'in', caseUids));

  // Set up the onSnapshot listener
  const unsubscribe = onSnapshot(casesQuery, (snapshot) => {
      const cases: Case[] = snapshot.docs.map((doc) => ({
        uid: doc.id,
        ...doc.data(),
      })) as Case[];

      // Call the callback with the updated data
      callback(cases);
    }
  );

  // Return the unsubscribe function to stop listening
  return unsubscribe;
};


export const fetchFilteredDataFromFirestore = (
  filters: CaseManagerSearchRequest,
  currentPage: number,
  lastVisible: any,
  onUpdate: (data: Case[], lastVisible: any) => void
): () => void => {
  const branch1: QueryFilterConstraint[] = [];

  if (filters.userEmail) {
    if (filters.showOrgCases) {
      // Filter by agentDomain (using the email’s domain)
      const emailDomain = filters.userEmail.substring(filters.userEmail.indexOf('@'));
      branch1.push(where("agentDomain", "==", emailDomain));
    } else {
      // Filter by agentEmail
      branch1.push(where("agentEmail", "==", filters.userEmail));
    }
  }

  if (filters.caseStatus) {
    branch1.push(where("caseStatus", "==", filters.caseStatus));
  }
  
  if (filters.firstName && filters.firstName.length > 1) {
    branch1.push(where("firstNameLower", "==", filters.firstName.toLowerCase()));
  }
  
  if (filters.lastName && filters.lastName.length > 1) {
    branch1.push(where("lastNameLower", "==", filters.lastName.toLowerCase()));
  }
  
  // Build branch 2: feedback conditions
  const branch2: QueryFilterConstraint[] = [
    where("feedbackRequest.requestedOf", "==", filters.userEmail),
    where("feedbackRequest.feedbackStatus", "==", REQUEST_STATUS.REQUESTED)
  ];
  
  // Wrap branch arrays into composite filters if needed:
  const branch1Filter = branch1.length > 1 ? and(...branch1) : branch1[0];
  const branch2Filter = branch2.length > 1 ? and(...branch2) : branch2[0];
  
  // Create a composite filter that requires: isDeleted == false AND (branch1 OR branch2)
  const compositeFilter = and(
    where("isDeleted", "==", false),
    or(branch1Filter, branch2Filter)
  );
  
  // Build the query using the composite filter as the only filter constraint.
  const combinedQuery = query(
    collection(db, "cases"),
    compositeFilter,
    orderBy("createdDate", "desc"),
    // Apply pagination if lastVisible exists.
    ...(lastVisible ? [startAfter(lastVisible)] : []),
    limit(filters.batchSize)
  );

  // Listener for the first query
  const unsubscribe = onSnapshot(combinedQuery, (snapshot) => {
    const combinedDocs = new Map();
  
    snapshot.forEach((doc) => {
      combinedDocs.set(doc.id, { uid: doc.id, ...doc.data() } as Case);
    });
  
    const lastVisible = snapshot.docs[snapshot.docs.length - 1];
  
    // Call your update callback with the combined results
    onUpdate(Array.from(combinedDocs.values()), lastVisible);
  });

  return unsubscribe;
};

export const useFilteredData = (
  filters: CaseManagerSearchRequest,
  currentPage: number,
  lastVisible: any,
  setLastVisible: React.Dispatch<React.SetStateAction<any>>
) => {
  const queryClient = useQueryClient();
  const unsubscribeRef = useRef<() => void>();

  const fetchFilteredData = () => {
    // If a subscription already exists, cancel it
    if (unsubscribeRef.current) {
      unsubscribeRef.current();
    }
    
    return new Promise<{ caseData: Case[]; hasNextPage: boolean }>((resolve) => {
      let hasNextPage = false;

      unsubscribeRef.current = fetchFilteredDataFromFirestore(
        filters,
        currentPage,
        lastVisible,
        (caseData, newLastVisible) => {
          console.log(filters);

          if (caseData.length > 0) {
            // Only update if needed
            if (newLastVisible !== lastVisible) {
              setLastVisible(newLastVisible);
            }
          }

          hasNextPage = caseData.length === filters.batchSize;
          
          // Update the query cache
          queryClient.setQueryData(['filteredData', filters, currentPage], {
            caseData,
            hasNextPage,
          });

          resolve({ caseData, hasNextPage });
        }
      );
    });
  };

  return useQuery(['filteredData', filters, currentPage], fetchFilteredData, {
    enabled: false, // Manually controlled
  });
};

