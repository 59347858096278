import { useEffect, useState } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { useAppSelector } from '../../../hooks/redux-hooks';
import { selectAuth } from '../../../redux/slices/authSlice';
import { db } from '../../../firebaseConfig';
import { carrierContacts } from '../../../types/carrierContacts';
import { createLocalDate, getDefaultMonthYear, getShortDate, months, years } from '../../../utils/dateTime';
import { getCaseByAppId } from '../../../services/case-data.service';
import { fetchInquiriesByCaseUid } from '../../../services/inquiry-data.service';
import { exportOQToExcel } from './offerqualityreportxls';
import XlsDownload from '../../../assets/xlsdownload.png';
import toast from 'react-hot-toast';
import './offerqualityreport.css';
import { useQuery } from 'react-query';
import { ExchangeRelay } from '../../../models/exchangeRelay';
import ExchangeHeader from '../../../components/ExchangeHeader/exchangeheader';

export type OfferQualityItem = {
  carrier?: string;
  applicant: string;
  policyId?: string;
  submitDate?: string;
  offerDate?: string;
  targetPremium?: string;
  faceAmount?: string;
  tentativeOffer?: string;
  placed?: string;
};

const OfferQualityReport = () => {
  const { defaultYear } = getDefaultMonthYear();
  const [exchangePartner, setExchangePartner] = useState<string>('');
  const [selectedMonth, setSelectedMonth] = useState<number | undefined>();
  const [selectedYear, setSelectedYear] = useState<number>(defaultYear);

  const { userData } = useAppSelector(selectAuth);

  const { data: offerQualityItems = [], isLoading, error } = useQuery( 
    // key to manage cached data and trigger refetches when dependencies change.
    ['fetchRecords', selectedMonth, selectedYear, exchangePartner],
    () => fetchRecords({ selectedMonth, selectedYear, exchangePartner, userData }),

    {
      // `enabled` determines whether the query should execute. 
      enabled: selectedMonth !== undefined && selectedYear !== undefined && !!exchangePartner,
      staleTime: 1000 * 60 * 30,
    }
  );

  const handleMonthChange = (e: React.ChangeEvent<HTMLSelectElement>) =>
    setSelectedMonth(parseInt(e.target.value, 10));

  const handleYearChange = (e: React.ChangeEvent<HTMLSelectElement>) =>
    setSelectedYear(parseInt(e.target.value, 10));

  const handleExcelExport = () => {
    if (offerQualityItems.length > 0 && selectedMonth !== undefined) {
      const fileName = `Offer Quality ${selectedMonth + 1}.${selectedYear}`;
      exportOQToExcel(offerQualityItems, fileName);
    } else {
      toast.error('There is no data to export.');
    }
  };

  useEffect(() => {
    setExchangePartner(userData?.exchangePartner || 'AIN');
  }, [userData]);

  if (error) return <p>Error: Unable to fetch records.</p>;
  
  return (
    <div className="offer-quality-container">
      <div className="oq-exchange-partner-header">
        <ExchangeHeader exchangePartner={exchangePartner} />
      </div>

      <div className="offer-logo-section">
        <h4>Offer Quality Report</h4>
      </div>

      <div className="report-logo-section">
        <h4>Offer Quality Report - {selectedMonth ? months[selectedMonth] : ''} {selectedYear}</h4>
      </div>

      <div  className="form-row offer-quality-filter">
        <div className="form-group">
          <label htmlFor="month">Month</label>
          <select id="month" value={selectedMonth} onChange={handleMonthChange}>
            <option></option>
            {months.map((month, index) => (
              <option key={index} value={index}>
                {month}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="year">Year</label>
          <select id="year" value={selectedYear} onChange={handleYearChange}>
            {years().map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group export-button">
          <div className="button-container">
            <button onClick={handleExcelExport} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
              <img src={XlsDownload} alt="Apeiron Gate" style={{ height: '32px', width: '32px' }}/>
            </button>
          </div>
        </div>
      </div>

      {isLoading && <h1>Loading...</h1>}
      {!isLoading && !error && offerQualityItems &&
        <div>
          <div className="offer-quality-wrapper">
            <table className="offer-quality-table">
              <thead>
                <tr>
                  <th>Carrier</th>
                  <th>Applicant</th>
                  <th>Policy ID</th>
                  <th>Submit Date</th>
                  <th>Offer Date</th>
                  <th>Target Premium</th>
                  <th>Face Amount</th>
                  <th>Tentative Offer</th>
                  <th>Placed</th>
                </tr>
              </thead>
              <tbody>
                {offerQualityItems.map((item, index) => {
                  const isNewApplicant = index === 0 || item.applicant !== offerQualityItems[index - 1].applicant;

                  return (
                    <tr key={index} style={{ borderTop: isNewApplicant ? '2px solid black' : 'none', }} >
                      <td>{item.carrier || ''}</td>
                      <td>{item.applicant}</td>
                      <td>{item.policyId || ''}</td>
                      <td>{item.submitDate}</td>
                      <td>{item.offerDate || ''}</td>
                      <td>{item.targetPremium || ''}</td>
                      <td>{item.faceAmount || ''}</td>
                      <td>{item.tentativeOffer || ''}</td>
                      <td>{item.placed || ''}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>      
      }
    </div>
  );
};

export default OfferQualityReport;

const fetchRecords = async ({
  selectedMonth,
  selectedYear,
  exchangePartner,
  userData,
}: {
  selectedMonth: number | undefined;
  selectedYear: number | undefined;
  exchangePartner: string;
  userData: any
}) => {
  if (selectedMonth === undefined || selectedYear === undefined || !exchangePartner) {
    throw new Error('Missing required parameters');
  }

  const carrierCodes = carrierContacts
    .filter((i) => i.exchangePartner === exchangePartner)
    .map((carrier) => carrier.code);

  if (carrierCodes.length < 1) {
    return [];
  }

  const month = selectedMonth + 1; // Adjust for 0-based index
  const year = selectedYear;

  const exchangeRelayRef = collection(db, 'exchangeRelay');
  const q = query(
    exchangeRelayRef,
    where('carrierCode', 'in', carrierCodes),
    where('month', '==', month),
    where('year', '==', year)
  );

  const querySnapshot = await getDocs(q);  
  let relayRecords: ExchangeRelay[] = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data(), }));

  //if carrier only show cases carrier has made an offer on
  if (userData?.isCarrier) {
    const carrierRelays = relayRecords.filter(record => record.carrierCode === userData?.carrierCode);
    const caseUids = carrierRelays.map(relay => relay.caseUid);
    relayRecords = relayRecords.filter(record => caseUids.includes(record.caseUid));
  } 

  const items = await Promise.all(
    relayRecords.map(async (doc) => createInvoiceItem(doc, userData))
  );

  items.sort((a, b) => a.applicant.localeCompare(b.applicant));
  return items;
};

const createInvoiceItem = async (exchangeRelay: any, userData: any): Promise<OfferQualityItem> => {
  const carrier = carrierContacts.find((contact) => contact.code === exchangeRelay.carrierCode);
  const unMasked = userData?.carrierCode === carrier?.code || !userData?.isCarrier;
  const carrierDisplay = unMasked ? carrier?.name : '*****';

  const item: OfferQualityItem = {
    applicant: '',
    carrier: carrierDisplay || '',
    offerDate: exchangeRelay.offerDate ? getShortDate(createLocalDate(exchangeRelay.offerDate)) : '',
    submitDate: exchangeRelay.sendDate || '',
    policyId: unMasked ? exchangeRelay.carrierId : '',
    tentativeOffer: `${exchangeRelay.tentativeOffer || ''} ${exchangeRelay.tentativeOfferSubCategory || ''}`.trim(),
  };

  try {
    if (exchangeRelay.appId) {
      const caseData = await getCaseByAppId(exchangeRelay.appId);
      if (caseData) {
        item.applicant = `${caseData.lastName}, ${caseData.firstName}`;
        item.placed = caseData.caseStatus === "Placed" && caseData.placedCarrier === exchangeRelay.carrierCode ? "Yes" : "";
      }
    }

    if (exchangeRelay.caseUid) {
      const inquiries = await fetchInquiriesByCaseUid(exchangeRelay.caseUid);
      if (inquiries.length > 0) {
        const [inquiry] = inquiries;
        item.targetPremium = inquiry.targetPremium;
        item.faceAmount = inquiry.faceAmount;
      }
    }
  } catch (error) {
    console.error('Error processing exchangeRelay:', error);
  }

  return item;
};