import { db } from '../firebaseConfig';
import {
  onSnapshot,
  collection,
  query,
  where,
  limit,
  orderBy,
  Timestamp
} from 'firebase/firestore';
import { ExchangeRelay } from '../models/exchangeRelay';

const exchangeRelayRef = collection(db, 'exchangeRelay');


export const carrierCaseManagerFetchRelays = (
  carrierCode: string,
  batchSize: number,
  callback: (relays: ExchangeRelay[]) => void
): (() => void) => {
  // 90 days in milliseconds
  const ninetyDaysAgo = Timestamp.fromDate(new Date(new Date().getTime() - 360 * 24 * 60 * 60 * 1000));
  
  const relaysQuery = query(
    exchangeRelayRef,
    where('carrierCode', '==', carrierCode),
    where('createdDate', '>=', ninetyDaysAgo),
    orderBy('createdDate', 'desc'),
    limit(batchSize)
  );

  // Listen for real-time updates
  const unsubscribe = onSnapshot(relaysQuery, (snapshot) => {
    const recentRelays: ExchangeRelay[] = snapshot.docs.map(doc => ({
      uid: doc.id,
      id: doc.id,
      ...doc.data(),
    })) as ExchangeRelay[];

    // Trigger the callback with the updated data
    callback(recentRelays);
  });

  // Return the unsubscribe function to stop listening
  return unsubscribe;
};
