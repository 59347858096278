
import { useEffect, useState } from "react";
import "./invoice.css"; // Import your CSS for styling
import { collection, query, where, getDocs } from "firebase/firestore";
import CarrierFilter from "../../../components/CarrierFilter/carrierfilter";
import { db } from "../../../firebaseConfig";
import { ExchangeRelay } from "../../../models/exchangeRelay";
import { InvoiceItem } from "../../../models/invoiceitem";
import { getCaseByAppId } from "../../../services/case-data.service";
import { fetchInquiriesByCaseUid } from "../../../services/inquiry-data.service";
import { carrierContacts } from "../../../types/carrierContacts";
import { getDefaultMonthYear, months } from "../../../utils/dateTime";
import ExchangeHeader from "../../../components/ExchangeHeader/exchangeheader";


const InvoiceReport = () => {
  const { defaultMonth, defaultYear } = getDefaultMonthYear();

  const [exchangePartner, setExchangePartner] = useState('');
  const [selectedCarrier, setSelectedCarrier] = useState('');
  const [selectedMonth, setSelectedMonth] = useState(defaultMonth);
  const [selectedYear, setSelectedYear] = useState(defaultYear);
  const [invoiceItems, setInvoiceItems] = useState<InvoiceItem[]>();

  const handleFilterChange = (carrier: string, month: number, year: number) => {
    setSelectedCarrier(carrier);
    setSelectedMonth(month);
    setSelectedYear(year);
  };

  const [fromInfo, setFromInfo] = useState({
    companyName: "Apeiron Gate",
    addressLine1: "8 The Green, Suite B",
    addressLine2: "Dover, DE 19901",
    phone: "(816) 769-8266",
  });

  const [invoiceInfo, setInvoiceInfo] = useState({
    invoiceNumber: "####",
    invoiceDate: "MM/DD/YYYY",
    invoiceSubtotal: "$0.00",
    invoiceTax: "$0.00",
    invoiceTrialCount: 0,
    invoiceTargetPremium: 0,
    amountDue: "$0.00",
  });

  const [carrierInfo, setCarrierInfo] = useState({
    carrierName: "CARRIER NAME",
    line1: "Carrier Address Line 1",
    line2: "Carrier Address Line 2",
    cityStateZip: "City, St Zip",
    phone: "Phone Number",
  });

  const [dueTerms, setDueTerms] = useState({
    dueDate: "MM/DD/YYYY",
    terms: "Net 30",
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, section: string, field: string) => {
    const value = e.target.value;

    switch (section) {
      case "fromInfo":
        setFromInfo((prev) => ({ ...prev, [field]: value }));
        break;
      case "invoiceInfo":
        setInvoiceInfo((prev) => ({ ...prev, [field]: value }));
        break;
      case "carrierInfo":
        setCarrierInfo((prev) => ({ ...prev, [field]: value }));
        break;
      case "dueTerms":
        setDueTerms((prev) => ({ ...prev, [field]: value }));
        break;
      default:
        break;
    }
  };

  //explian
  const handleInvoiceItemChange = (index: number, field: keyof InvoiceItem, value: string | number) => {
    setInvoiceItems((prev) =>
      prev?.map((item, i) =>
        i === index ? { ...item, [field]: value } : item
      )
    );
  };

  useEffect(() => {
    const fetchRecords = async () => {
      if (!selectedCarrier || selectedMonth === undefined || selectedYear === undefined) {
        return;
      }
  
      try {
        const month = selectedMonth + 1; // Adjust for 0-based index
        const year = selectedYear;
  
        const exchangeRelayRef = collection(db, "exchangeRelay");
        const q = query(
          exchangeRelayRef,
          where("carrierCode", "==", selectedCarrier),
          where("month", "==", month),
          where("year", "==", year)
        );
  
        const querySnapshot = await getDocs(q);

        const counts: Record<string, number> = {};
        let totalTargetPremium: number = 0;
        const promises = querySnapshot.docs.map(async (doc) => {
          const exchangeRelay = doc.data() as ExchangeRelay;
          if (exchangeRelay.appId) {
            const caseData = await getCaseByAppId(exchangeRelay.appId);
            if (caseData) {
              const agencyName = caseData.agency || "Unknown";
              counts[agencyName] = (counts[agencyName] || 0) + 1;
            }
          }

          if (exchangeRelay.caseUid) {
            const informalInquiries = await fetchInquiriesByCaseUid(exchangeRelay.caseUid);
            if (informalInquiries.length > 0) {
              const informalInquiry = informalInquiries[0];
              const targetPremium = Number(informalInquiry.targetPremium);
              if (!isNaN(targetPremium)) {
                totalTargetPremium = totalTargetPremium + targetPremium;
              } else {
                return 0;
              }
            }
          }
        });

        await Promise.all(promises);

        let totalCount = 0;
        const invoiceItems: InvoiceItem[] = [];
        for (const [agencyName, count] of Object.entries(counts)) {
          totalCount += count;
          invoiceItems.push({
            agency : `${agencyName} - ${month}/${year} Trial Packages Received`, 
            countReceived: count,
            price: 30
           })
        }

        setInvoiceItems(invoiceItems);
        setInvoiceInfo((prev) => ({
          ...prev,
          invoiceTargetPremium: totalTargetPremium,
          invoiceTrialCount: totalCount,
          invoiceSubtotal: `$${totalCount * 30}`,
          amountDue: `$${totalCount * 30}`,
        }));

      } catch (error) {
        console.error("Error fetching records:", error);
      }
    };

    //set inovice date
    const today = new Date();
    const formattedDate = `${(today.getMonth() + 1).toString().padStart(2, "0")}/${today
      .getDate()
      .toString()
      .padStart(2, "0")}/${today.getFullYear()}`;

    setInvoiceInfo((prev) => ({
      ...prev,
      invoiceDate: formattedDate,
    }));

    //set exchange partner
    const carrier = carrierContacts.find(i => i.code === selectedCarrier)
    if (carrier?.exchangePartner) {
      setExchangePartner(carrier?.exchangePartner)
    } else {
      setExchangePartner('')
    }

    
    setCarrierInfo((prev) => ({
      ...prev,
      carrierName: `${carrier?.name || ''}`,
    }));
  
    fetchRecords();
  }, [selectedCarrier, selectedMonth, selectedYear]);

  return (
    <div className="invoice-page-container">
      
      <div className="form-section page-title">
        <h4>Invoice</h4>
      </div>

      <div className="form-row carrier-filter">
        <CarrierFilter
          onFilterChange={handleFilterChange}
          defaultCarrier={selectedCarrier}
          defaultMonth={selectedMonth}
          defaultYear={selectedYear}
        />
      </div>
      
      <div className="invoice-container">
        <div className="exchange-partner-header">
          <ExchangeHeader exchangePartner={exchangePartner} />
        </div>
        {/* Header */}
        <div className="header">
          <div className="from-section">
            <h5>From:</h5>
            <div>
              <input value={fromInfo.companyName} onChange={(e) => handleInputChange(e, "fromInfo", "companyName")} />
            </div>
            <div>
              <input value={fromInfo.addressLine1} onChange={(e) => handleInputChange(e, "fromInfo", "addressLine1")} />
            </div>
            <div>
              <input value={fromInfo.addressLine2} onChange={(e) => handleInputChange(e, "fromInfo", "addressLine2")} />
            </div>
            <div>
              <input value={fromInfo.phone} onChange={(e) => handleInputChange(e, "fromInfo", "phone")} />
            </div>
          </div>
          <div className="invoice-info">
            <p>
              <strong>Invoice Number:</strong>
              <input value={invoiceInfo.invoiceNumber} onChange={(e) => handleInputChange(e, "invoiceInfo", "invoiceNumber")} />
            </p>
            <p>
              <strong>Invoice Date:</strong>
              <input value={invoiceInfo.invoiceDate} onChange={(e) => handleInputChange(e, "invoiceInfo", "invoiceDate")} />
            </p>
            <p>
              <strong>Amount Due:</strong>
              <input value={invoiceInfo.amountDue} onChange={(e) => handleInputChange(e, "invoiceInfo", "amountDue")} />
            </p>
          </div>
        </div>

        {/* Bill To Section */}
        <div className="bill-to-section">
          <div className="bill-to">
            <h5>Bill To:</h5>
            <div>
              <input value={carrierInfo.carrierName} onChange={(e) => handleInputChange(e, "carrierInfo", "carrierName")} />
            </div>
            <div>
              <input value={carrierInfo.line1} onChange={(e) => handleInputChange(e, "carrierInfo", "line1")} />
            </div>
            <div>
              <input value={carrierInfo.line2} onChange={(e) => handleInputChange(e, "carrierInfo", "line2")} />
            </div>
            <div>
              <input value={carrierInfo.cityStateZip} onChange={(e) => handleInputChange(e, "carrierInfo", "cityStateZip")} />
            </div>
            <div>
              <input value={carrierInfo.phone} onChange={(e) => handleInputChange(e, "carrierInfo", "phone")} />
            </div>
          </div>
          <div className="due-terms">
            <table className="due-table">
              <thead>
                <tr>
                  <th>Due Date</th>
                  <th>Terms</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <input value={dueTerms.dueDate} onChange={(e) => handleInputChange(e, "dueTerms", "dueDate")} />
                  </td>
                  <td>
                    <input value={dueTerms.terms} onChange={(e) => handleInputChange(e, "dueTerms", "terms")} />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {/* Invoice Items To Section */}
        <div className="invoice-items">
          <table className="invoice-table">
            <thead>
              <tr>
                <th>Description</th>
                <th>Quantity</th>
                <th>Price</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {invoiceItems?.map((item, index) => (
                <tr key={index}>
                  <td className="long">
                    {item.agency}
                  </td>
                  <td className="center-text short">
                    <input className="short" value={item.countReceived} onChange={(e) => handleInvoiceItemChange(index, "countReceived", parseInt(e.target.value, 10) || 0)} />
                  </td>
                  <td className="center-text short">
                    <input className="short center-text" value={`${item.price}`} onChange={(e) => handleInvoiceItemChange(index, "price", parseInt(e.target.value, 10) || 0)} />
                  </td>
                  <td className="center-text short">
                    <input className="short center-text" value={`$${item.countReceived * item.price}`} readOnly  />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Invoice Totals Section */}
        <div className="final-section">
          <div className="comments-section">
            <p>
              Thank you for participating in the { exchangePartner === 'AIN' ? 'AIN' : 'Lion Street '} Exchange. In the month of {months[selectedMonth]}
              , {carrierInfo.carrierName} received {invoiceInfo.invoiceTrialCount} Trial Packages totaling ${invoiceInfo.invoiceTargetPremium} in target premium. 
            </p>
            <p>
              Questions regarding this invoice or the attached documentation? Please contact our { exchangePartner === 'AIN' ? 'AIN' : 'Lion Street '} Exchange Service provider
              , Apeiron Gate LLC, at (816) 769-8266.
            </p>
          </div>
          <div className="invoice-totals">
            <p>
              <strong>Subtotal:</strong>
              <input value={invoiceInfo.invoiceSubtotal} onChange={(e) => handleInputChange(e, "invoiceInfo", "invoiceSubtotal")} />
            </p>
            <p className="underline">
              <strong>Sales Tax:</strong>
              <input value={invoiceInfo.invoiceTax} onChange={(e) => handleInputChange(e, "invoiceInfo", "invoiceTax")} />
            </p>
            <p>
              <strong>Amount Due:</strong>
              <input value={invoiceInfo.amountDue} onChange={(e) => handleInputChange(e, "invoiceInfo", "amountDue")} />
            </p>
          </div>
        </div>
      </div>

    </div>
  );
};

export default InvoiceReport;
