import * as XLSX from "xlsx";
import { OfferQualityItem } from "./offerqualityreport";


export const exportOQToExcel = (items: OfferQualityItem[], fileName: string) => {
  // Add the  table
  const headers = ["Carrier", "Applicant", "Policy ID", "Submit Date", "Offer Date", "Target Premium", "Face Amount", "Tentative Offer", "Placed"];
  const data = items.map((item) => [
    item.carrier || "",
    item.applicant || "",
    item.policyId || "",
    item.submitDate || "",
    item.offerDate || "",
    item.targetPremium || "",
    item.faceAmount || "",
    item.tentativeOffer || "",
    item.placed || "",
  ]);

  // Combine summary and detail tables
  const combinedData = [
    headers,
    ...data,
  ];

  // Create a worksheet
  const worksheet = XLSX.utils.aoa_to_sheet(combinedData);

  // Create a workbook
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Offer Data");

  // Export to Excel
  XLSX.writeFile(workbook, `${fileName}.xlsx`);
};
  
