import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./pages/auth/Login/Login";
import Register from "./pages/auth/Register/Register";
import Reset from "./pages/auth/Reset";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import Home from "./pages/Home/Home";
import Header from "./components/Header/header";
import { Toaster } from "react-hot-toast";
import SubHeader from "./components/SubHeader/subHeader";
import UnauthorizePage from "./pages/auth/unauthorize";
import CaseDetail from "./pages/Case/CaseDetail/casedetail";
import { RequireAuth } from "./components/RequireAuth/requireAuth";
import SendQueue from "./pages/Case/SendQueue/sendqueue";
import OrderRecords from "./pages/OrderRecords/orderrecords";
import CaseUpload from "./pages/Case/CaseUpload/caseupload";
import { AuthManager } from "./utils/AuthManager";
import DocumentViewer from "./components/DocumentViewer/documentviewer";
import AdminCaseManager from "./pages/Admin/AdminCaseManager/admincasemanager";
import AdminSendQueue from "./pages/Admin/AdminSendQueue/adminsendqueue";
import OfferDetailReport from "./pages/Reports/OfferDetailReport/offerdetailreport";
import InvoiceBackupReport from "./pages/Admin/InvoiceBackupReport/invoicebackupreport";
import OfferQualityReport from "./pages/Reports/OfferQualityReport/offerqualityreport";
import CaseManager from "./pages/Case/CaseManager/casemanager";
import CarrierCaseManager from "./pages/CarrierCaseManager/carriercasemanager";
import BgaCaseManager from "./pages/BgaCaseManager/bgacasemanager";
import InvoiceReport from "./pages/Admin/Invoice/invoice";
import OpportunityReport from "./pages/Reports/OpportunityReport/opportunityreport";

function App() {
  return (
    <BrowserRouter>
      <AuthManager />
      <Header></Header>
      <SubHeader></SubHeader>
      <Routes>
        <Route path='/' element={<Home />}>
          <Route path='unauthorized' element={<UnauthorizePage />} />
        </Route>
        <Route>
          <Route path='register' element={<Home />} />
          <Route path='register/:partner' element={<Register />} />
        </Route>
        <Route>
          <Route path='login' element={<Login />} />
        </Route>
        <Route>
          <Route path='forgotpassword' element={<Reset />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={['admin', 'bga']} />}>
          <Route path="/bgacasemanager" element={<BgaCaseManager />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={['admin', 'carrier']} />}>
          <Route path="/offermanager" element={<CarrierCaseManager />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[]} excludedRoles={['carrier']} />}>
          <Route path="/casemanager" element={<CaseManager />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[]} excludedRoles={['carrier', 'bga']} />}>
          <Route path="/caseupload" element={<CaseUpload />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[]} excludedRoles={['carrier']} />}>
          <Route path="/case/:uid" element={<CaseDetail />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[]} />}>
          <Route path="/case/:uid/summary" element={<DocumentViewer />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[]} />}>
          <Route path="/case/:uid/offerDetail" element={<OfferDetailReport />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={['admin', 'carrier', 'offerquality']} />}>
          <Route path="/offerQuality" element={<OfferQualityReport />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={['admin', 'opportunityreport']} excludedRoles={['carrier']} />}>
          <Route path="/opportunityreport" element={<OpportunityReport />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[]} excludedRoles={['carrier', 'bga']} />}>
          <Route path="/sendqueue/:uid/:appId" element={<SendQueue />} />
        </Route>
        <Route>
          <Route path='/orderrecords' element={<OrderRecords />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={['admin']} />}>
          <Route path="/admincasemanager" element={<AdminCaseManager />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={['admin']} />}>
          <Route path="/adminsendqueue/:uid/:appId" element={<AdminSendQueue />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={['admin']} />}>
          <Route path="/invoicebackup" element={<InvoiceBackupReport />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={['admin']} />}>
          <Route path="/invoice" element={<InvoiceReport />} />
        </Route>
      </Routes>
      <Toaster />
    </BrowserRouter>
  );
}

export default App;
