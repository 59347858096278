// ExchangeHeader.tsx
import React from 'react';
import AinExchangeLogo from '../../assets/ainexchange/AINExchange_Logo.png';
import LsExchangeLogo from '../../assets/ainexchange/LSExchange_Logo.png';

interface ExchangeHeaderProps {
  exchangePartner: string;
}

const ExchangeHeader: React.FC<ExchangeHeaderProps> = ({ exchangePartner }) => {
  return (
    <>
      {exchangePartner === 'AIN' && (
        <img className="exchange-image" src={AinExchangeLogo} alt="AIN Exchange Logo" />
      )}
      {exchangePartner === 'LS' && (
        <img className="exchange-image" src={LsExchangeLogo} alt="LS Exchange Logo" />
      )}
    </>
  );
};

export default ExchangeHeader;
