import { useEffect, useState, useMemo } from 'react';
import { collection, getDocs, orderBy, query, Timestamp, where } from 'firebase/firestore';
import { useAppSelector } from '../../../hooks/redux-hooks';
import { selectAuth } from '../../../redux/slices/authSlice';
import { db } from '../../../firebaseConfig';
import { carrierContacts } from '../../../types/carrierContacts';
import { createLocalDate, getShortDate } from '../../../utils/dateTime';
import { fetchInquiriesByCaseUid } from '../../../services/inquiry-data.service';
import XlsDownload from '../../../assets/xlsdownload.png';
import './opportunityreport.css';
import { useQuery } from 'react-query';
import { ExchangeRelay } from '../../../models/exchangeRelay';
import { Case } from '../../../models/Case/case';
import { Inquiry } from '../../../models/Case/inquiry';
import { getAgency } from '../../../services/agencyMapping-data.service';
import { exportORToExcel } from './opportunityreportxls';
import toast from 'react-hot-toast';
import ExchangeHeader from '../../../components/ExchangeHeader/exchangeheader';

export type OpportunityItem = {
  marketingRep?: string;
  caseManager?: string;
  agent?: string;
  applicant: string;
  carrier?: string;
  offerDate?: string;
  targetPremium?: string;
  faceAmount?: string;
  tentativeOffer?: string;
  placed?: string;
};

interface SearchParams {
  agentDomainFilter: string;
  startDate: string;
  endDate: string;
}

const OpportunityReport = () => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [exchangePartner, setExchangePartner] = useState<string>('');
  const [agentDomainFilter, setAgentDomainFilter] = useState('');
  const [agency, setAgency] = useState('');
  const [searchParams, setSearchParams] = useState<SearchParams | null>(null);
  const [selectedName, setSelectedName] = useState("All");

  const { userData } = useAppSelector(selectAuth);

  // Use react-query to fetch cases
  const {
    data: opportunityItems = [],
    isLoading,
    error,
  } = useQuery(
    ['fetchCases', searchParams],
    () =>
      // searchParams is non-null because enabled ensures that.
      fetchData({
        agentDomainFilter: searchParams!.agentDomainFilter,
        startDate: searchParams!.startDate,
        endDate: searchParams!.endDate,
      }),
    {
      enabled: !!searchParams, // Query only runs when searchParams exists.
      staleTime: 1000 * 60 * 30,
    }
  );

  // Set up initial data from the authenticated user.
  useEffect(() => {
    setExchangePartner(userData?.exchangePartner || 'AIN');
    if (userData?.email) {
      const emailDomain = userData.email.substring(userData.email.indexOf('@')) || '';
      setAgentDomainFilter(emailDomain);
      if (userData?.roles?.includes('admin')) {
        setAgentDomainFilter("@advisorsexcel.com");
      }
      getAgency(userData.email).then((agencyMapping) => {
        const agencyName = agencyMapping ?? userData.agency;
        setAgency(agencyName);
      });
    }
  }, [userData]);

  const handleStartDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEndDate(event.target.value);
  };

  // When the Search button is clicked, update the searchParams state.
  const handleSearch = () => {
    if (!startDate || !endDate) {
      toast.error('Please select both start and end dates.');
      return;
    }
    setSearchParams({ agentDomainFilter, startDate, endDate });
  };

  const handleExcelExport = () => {
    if (opportunityItems.length > 0) {
      const fileName = `Opportunity Report ${startDate}.${endDate}`;
      exportORToExcel(opportunityItems, fileName);
    } else {
      toast.error('There is no data to export.');
    }
  };

  // Compute a distinct list of names from Marketing Rep, Case Manager, and Agent fields.
  const distinctNames: string[] = useMemo(() => {
    const namesSet = new Set<string>();
    opportunityItems.forEach(item => {
      if (item.marketingRep) namesSet.add(item.marketingRep);
      if (item.caseManager) namesSet.add(item.caseManager);
      if (item.agent) namesSet.add(item.agent);
    });
    return Array.from(namesSet).sort();
  }, [opportunityItems]);

  // Filter opportunity items based on selectedName.
  const filteredOpportunityItems = selectedName === "All"
    ? opportunityItems
    : opportunityItems.filter(item =>
        item.marketingRep === selectedName ||
        item.caseManager === selectedName ||
        item.agent === selectedName
      );

  if (error) return <p>Error: Unable to fetch records.</p>;

  return (
    <div className="opportunity-container">
      <div className="oq-exchange-partner-header">
        <ExchangeHeader exchangePartner={exchangePartner} />
      </div>

      <div className="offer-logo-section">
        <h4>{agency} Opportunity Report</h4>
      </div>

      <div className="report-logo-section">
        <h4>{agency} Opportunity Report</h4>
      </div>

      <div className="report-logo-subsection">
        {searchParams && (
          <h6>
            {getShortDate(createLocalDate(searchParams.startDate))} -{' '}
            {getShortDate(createLocalDate(searchParams.endDate))}
          </h6>
        )}
      </div>

      <div className="form-row opportunity-filter">
        <div className="form-group">
          <label htmlFor="startdate">Start Date</label>
          <input
            id="startdate"
            type="date"
            value={startDate}
            onChange={handleStartDateChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="enddate">End Date</label>
          <input
            id="enddate"
            type="date"
            value={endDate}
            onChange={handleEndDateChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="nameFilter">Filter by Name</label>
          <select id="nameFilter" value={selectedName} onChange={(e) => setSelectedName(e.target.value)} >
            <option value="All">All</option>
            {distinctNames.map((name) => (
              <option key={name} value={name}>
                {name}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group filter-button">
          <button className='shopped-button' onClick={handleSearch}>Search</button>
        </div>

        <div className="form-group export-button">
          <div className="button-container">
            <button
              onClick={handleExcelExport}
              style={{ background: 'none', border: 'none', cursor: 'pointer' }}
            >
              <img
                src={XlsDownload}
                alt="Download Excel"
                style={{ height: '32px', width: '32px' }}
              />
            </button>
          </div>
        </div>
      </div>

      {isLoading && <h1>Loading...</h1>}
      {!isLoading && !error && filteredOpportunityItems && (
        <div>
          <div className="opportunity-wrapper">
            <table className="opportunity-table">
              <thead>
                <tr>
                  <th>Marketing Rep</th>
                  <th>Case Manager</th>
                  <th>Agent</th>
                  <th>Applicant</th>
                  <th>Carrier</th>
                  <th>Offer Date</th>
                  <th>Target Premium</th>
                  <th>Face Amount</th>
                  <th>Tentative Offer</th>
                  <th>Placed</th>
                </tr>
              </thead>
              <tbody>
                {filteredOpportunityItems.map((item, index) => {
                  const isNewApplicant = index === 0 || item.applicant !== filteredOpportunityItems[index - 1].applicant;
                  return (
                    <tr key={index} style={{ borderTop: isNewApplicant ? '2px solid black' : 'none', }} >
                      <td>{item.marketingRep || ''}</td>
                      <td>{item.caseManager || ''}</td>
                      <td>{item.agent || ''}</td>
                      <td>{item.applicant || ''}</td>
                      <td>{item.carrier || ''}</td>
                      <td>{item.offerDate || ''}</td>
                      <td>{item.targetPremium || ''}</td>
                      <td>{item.faceAmount || ''}</td>
                      <td>{item.tentativeOffer || ''}</td>
                      <td>{item.placed || ''}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default OpportunityReport;

// --- Data Fetching Functions ---
export const fetchData = async ({
  agentDomainFilter,
  startDate,
  endDate,
}: any): Promise<OpportunityItem[]> => {
  // Convert JavaScript Date objects to Firestore Timestamps if needed.
  const startTimestamp = startDate instanceof Timestamp ? startDate : Timestamp.fromDate(new Date(startDate));
  const endTimestamp = endDate instanceof Timestamp ? endDate : Timestamp.fromDate(new Date(endDate));

  // Reference the "cases" collection and build the query.
  const casesRef = collection(db, "cases");
  const casesQuery = query(
    casesRef,
    where("agentDomain", "==", agentDomainFilter),
    where("createdDate", ">=", startTimestamp),
    where("createdDate", "<=", endTimestamp),
    orderBy("createdDate", "desc")
  );

  // Execute the query.
  const querySnapshot = await getDocs(casesQuery);
  const cases: Case[] = querySnapshot.docs.map((doc) => ({
    uid: doc.id,
    ...doc.data(),
  })) as Case[];

  // For each case, get its opportunity items and flatten the result.
  const opportunityItemsNested: OpportunityItem[][] = await Promise.all(
    cases.map((caseData) => createOpportunityItems(caseData))
  );

  // Flatten the nested array and filter out any empty items.
  const opportunityItems: OpportunityItem[] = opportunityItemsNested.flat().filter(Boolean);
  return opportunityItems;
};


const createOpportunityItems = async (caseData: Case): Promise<OpportunityItem[]> => {
  const items: OpportunityItem[] = [];
  try {
    // Retrieve the first inquiry related to the case.
    let inquiry: Inquiry | null = null;
    if (caseData.uid) {
      const inquiries = await fetchInquiriesByCaseUid(caseData.uid);
      if (inquiries.length > 0) {
        inquiry = inquiries[0];
      }
    }

    // Query the "exchangeRelay" collection for records related to this case.
    const relayCollection = collection(db, "exchangeRelay");
    const relayQuery = query(relayCollection, where("caseUid", "==", caseData.uid));
    const relaySnapshot = await getDocs(relayQuery);
    const relayRecords: ExchangeRelay[] = relaySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    })) as ExchangeRelay[];

    // Process each relay record and create an opportunity item.
    for (const relay of relayRecords) {
      const opportunityItem: OpportunityItem = {
        applicant: `${caseData.firstName?.trim() || ""} ${caseData.lastName?.trim() || ""}`.trim(),
        marketingRep: `${caseData.contact?.firstName?.trim() || ""} ${caseData.contact?.lastName?.trim() || ""}`.trim(),
        caseManager: `${caseData.agentfirstName?.trim() || ""} ${caseData.agentlastName?.trim() || ""}`.trim(),
        agent: `${caseData.agent1?.firstName?.trim() || ""} ${caseData.agent1?.lastName?.trim() || ""}`.trim(),
        targetPremium: inquiry?.targetPremium || "",
        faceAmount: inquiry?.faceAmount || "",
        carrier: "",
        offerDate: relay.offerDate ? getShortDate(createLocalDate(relay.offerDate as any)) : "",
        tentativeOffer: `${relay.tentativeOffer || ''} ${relay.tentativeOfferSubCategory || ''}`.trim(),
        placed: caseData.caseStatus === "Placed" && caseData.placedCarrier === relay.carrierCode ? "Yes" : "",
      };

      // Find and assign the carrier name.
      const carrier = carrierContacts.find((contact) => contact.code === relay.carrierCode);
      opportunityItem.carrier = carrier?.name || "";

      items.push(opportunityItem);
    }
  } catch (error) {
    console.error("Error processing exchangeRelay for case:", caseData.uid, error);
  }
  return items;
};
